
import BagOut from './BagOut'

export default {
    name: 'BagSubmit',
    components: {
        BagOut,
    },
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object,
        edit: Object,
        goods: Object,
        amt: Object,
        goodsInfo: Object
    },
    data() {
        return {
            hasLogin: false,
            loading: false,
            active: 0,
            country: {},
            currency: {},
            rsAmt: {                 // 购物车金额信息
                list: []
            },
            popup: {
                visible: false
            },
            out: {                  // 缺货
                visible: false,
                list: []
            },
            params: {
                wishId: [],          // 移入心愿单id
                placeProduct: [],    // 下单信息
            },
            address: {
                first: '',
                list: []
            }
        }
    },
    computed: {},
    watch: {
        amt: {
            handler() {
                this.setAmtData()
            },
            deep: true
        }
    },
    created() {},
    mounted() {
        this.initialSet()
        this.hasLogin && this.getAddressListAjax()
    },
    methods: {
        // 获取地址列表
        getAddressListAjax() {
            this.$api.address.getAddressList({
                filterTag: true,
                countryCode: this.country.countryCode
            }).then(response => {
                const res = response.result || [];
                this.address.list = res
                this.address.first = this.address.list[0]
            })
        },
        // 数据设置
        setAmtData() {
            const rsAmt = JSON.parse(JSON.stringify(this.amt));
            // this.rsAmt = {...this.rsAmt, ...rsAmt}
            this.rsAmt = rsAmt
            this.rsAmt.list = []
            this.setAmtDetailData()
        },
        setAmtDetailData() {
            let allList = [];
            let discountList = [{
                title: `${this.$translate('Above discounts does not include coupon deals, please review at the next page')}.`,
                price: this.rsAmt.cartTotalDiscountAmt
            }];
            let couponList = [{
                title: '',
            }];
            let activeVisible = false;
            let activeList = [{
                // 捆绑活动
                title: this.$translate('Bundle Sale'),
                tip: `-${this.$price(this.rsAmt.displayBindDiscount)}`,
                price: this.rsAmt.bindDiscount
            }, {
                // 单品
                title: this.$translate('Specials'),
                tip: `-${this.$price(this.rsAmt.displaySpecialDiscount)}`,
                price: this.rsAmt.specialDiscount
            }, {
                // 闪购活动
                title: this.$translate('Flash Sale'),
                tip: `-${this.$price(this.rsAmt.displayFlashDiscount)}`,
                price: this.rsAmt.flashDiscount
            }, {
                // 满折/满减活动
                title: this.$translate('Buy More Save More'),
                tip: `-${this.$price(this.rsAmt.displayFullDiscount)}`,
                price: this.rsAmt.fullDiscount
            }, {
                // 满赠活动
                title: this.$translate('Free Gift(s) For You'),
                tip: `-${this.$price(this.rsAmt.displayPresentDiscount)}`,
                price: this.rsAmt.presentDiscount
            }, {
                // 新人专享
                title: this.$translate('New User Only'),
                priceText: `-${this.$price(this.rsAmt.displayNewcomerDiscount)}`,
                price: this.rsAmt.newcomerDiscount
            }, {
                // 会员
                title: `V${this.goodsInfo.userLevel}: ${this.$translate('VIP SERVICE')}`,
                tip: `-${this.$price(this.rsAmt.displayVipSerDiscountAmount)}`,
                price: this.rsAmt.vipSerVipDiscountAmount
            }];
            activeList.some(p => {
                if(p.price) {
                    activeVisible = true
                    return true
                }
            })
            allList = [{
                title: this.$translate('Promotions'),
                list: activeList,
                visible: activeVisible
            }, {
                title: this.$translate('Expected to Save'),
                list: couponList,
                type: 2,
                visible: this.rsAmt.code
            }, {
                title: this.$translate('Discount Total'),
                tip: `-${this.$price(this.rsAmt.displayCartTotalDiscountAmt)}`,
                list: discountList,
                visible: this.rsAmt.cartTotalDiscountAmt
            }];
            this.rsAmt.list = allList
            this.rsAmt.visible = false
            this.rsAmt.list.some(p => {
                if(p.visible) {
                    this.rsAmt.visible = true
                    return true
                }
            })
        },
        // 优惠券说明
        handleCouponTip() {
            this.$Dialog.alert({
                message: `${this.$translate('This is an estimated price, the actual price after discount will be calculated at checkout')}.`,
                confirmButtonText: this.$translate('OK'),
                confirmButtonColor: '#333'
            }).then(() => {
            }).catch(() => {})
        },
        // submit
        setCheckStockAjax() { // submit-库存校验
            return new Promise((resolve, reject) => {
                this.$api.bag.setBagStock().then(response => {
                    if(response.code == 'success') {
                        const res = response.result;
                        resolve(res)
                    }else{
                        this.loading = false
                        reject()
                    }
                })
            })
        },
        setParamsData() { // submit-购物车参数
            let wishId = [];
            let placeProductArr = [];
            this.goods.list.forEach(p => {
                p.cartItems.forEach(c => {
                    // 心愿单
                    if(this.edit.id.includes(c.id)) {
                        wishId.push({
                            cartId: c.id,
                            spuId: c.spuId
                        })
                    }
                    // 下单
                    if(c.goodsType && c.onSelect) {
                        placeProductArr.push(c)
                        // 贺卡
                        if(c.greetingCardItems) {
                            placeProductArr.push(...c.greetingCardItems)
                        }
                    }
                })
                // 满赠赠品
                const onSelectFreeGift = p?.activityAmt?.onSelectFreeGift;
                if(p.goodsType == 2 && onSelectFreeGift) {
                    onSelectFreeGift.forEach(c => {
                        const defaultC = {
                            goodsType: 3,
                            customizeType: 1,
                            pitchOnNewcomerGoods: false
                        };
                        placeProductArr.push({...c, ...defaultC})
                    })
                }
            })
            this.params.wishId = wishId
            this.params.placeProduct = placeProductArr
        },
        handleSelect() { //submit-全选
            this.goods.hasAllSelect = !this.goods.hasAllSelect
            this.$fnApi.debounce(() => {
                this.middleEmit.fn('setChooseProduct', {
                    id: this.goods.noInvalidId,
                    onSelect: this.goods.hasAllSelect
                })
            }, 300)
        },
        handleTip() { // submit-购物车金额明细
            if(!this.rsAmt.visible) return
            this.popup.visible = true
        },
        handleSubmit() { // submit-下单
            this.$storage.remove('order/shippingMethod')
            if(!this.goods.selectId) {
                this.$Toast(this.$translate(`Please select the items you'd like to checkout with`))
                return
            }
            this.loading = true
            this.$fnApi.debounce(() => {
                this.setParamsData()
                this.setPlaceOrder()
            }, 200)
        },
        setPlaceOrderStorage() { // submit-下单缓存
            this.$storage.set('order/placeProduct', this.params.placeProduct)
            this.$storage.set('order/placePage', location.href)
        },
        setAddress() { // submit-下单地址校验
            let orderAddress = this.$storage.get('order/placeAddress');
            this.loading = false
            if(!orderAddress && this.address.first) {
                orderAddress = this.address.first
                this.$storage.set('order/placeAddress', this.address.first)
            }
            if(!orderAddress) {
                this.$fnApi.debounce(() => {
                    this.$storage.remove('countryStorage')
                    this.$router.push({
                        name: 'Address',
                        query: {
                            key: 'order/placeAddress',
                            from: 'cart'
                        }
                    })
                }, 10)
            } else {
                this.$router.push({
                    name: 'OrderConfirm',
                    query: {
                        from: 'cart'
                    }
                })
            }
        },
        setPlaceOrder() { // submit-下单
            this.setCheckStockAjax().then(res => {
                if(!res?.length) {
                    this.setPlaceOrderStorage()
                    this.setBuried()
                    this.setAddress()
                } else {
                    this.out.visible = true
                    this.out.list = res
                }
            })
        },
        // 购物车编辑---------------
        setWishProductAjax() {
            this.$api.product.setAddWish({
                moveToCollectParam: this.params.wishId
            }).then(res => {
                this.edit.hasEdit = false
                this.middleEmit.fn('getBagAjax')
                this.$updateBagQuantity()
            })
        },
        handleEdit() { // 购物车编辑-全选
            this.edit.id = this.edit.hasAllSelect ? '' : this.goods.allId
        },
        handleDelete() { // 购物车编辑-删除
            if(!this.edit.id) {
                this.$Toast(this.$translate('Please Select'))
                return
            }
            this.middleEmit.fn('emitEditDelete')
        },
        handleWish() { // 购物车编辑-心愿单
            if(!this.$login()) {
                this.$router.push({
                    name: 'Login'
                })
                return
            }
            if(!this.edit.id) {
                this.$Toast(this.$translate('Please Select'))
                return
            }
            this.$Dialog.confirm({
                message: `${this.$translate('Are you sure to moving the item(s) to wishlist from shopping bag')}?`,
                confirmButtonText: this.$translate('Yes'),
                cancelButtonText: this.$translate('No')
            }).then(() => {
                this.setParamsData()
                this.setWishProductAjax()
            }).catch(() => {})
        },
        // 埋点
        setSymbolReplace(val) {
            try {
                return val && val.includes(',') ? val.replaceAll('.', '').replaceAll(',', '.').replace(/[^\d.-]/g, '') : val
            } catch {
                return val
            }
        },
        batCate(detail) {
            let batCate = detail?.backCat?.pullPathEn.split('/') || [];
            return batCate?.pop();
        },
        setBuried() {
            let fbq = {
                content_category: 'product',
                content_type: 'product_group',
                content_ids: [],
                contents: [],
                num_items: 0,
            };
            let gtagItem = []
            this.params.placeProduct.forEach((p,index) => {
                fbq.content_ids.push(p.productCode)
                fbq.contents.push({
                    id: p.productCode,
                    quantity: p.quantity
                })
                fbq.num_items += p.quantity

                gtagItem.push({
                    item_id:p.skuId,
                    item_name:p.productName,
                    quantity:p.quantity,
                    price:p.displaySkuPrice,
                    group_item_id: p.productCode,
                    affiliation: 'YFN',
                    item_brand: 'YFN',
                    index: index,
                    item_category: this.batCate(p.backCat),
                    item_variant: p.skuCode,
                })
            })
            // gtag 开始结账
            this.$fnApi.buried.gtag({
                type: 'begin_checkout',
                params: {
                    currency:'USD',
                    items: gtagItem,
                }
            })
            // fb
            this.$fnApi.buried.fbq({
                type: 'InitiateCheckout',
                params: {
                    currency: 'USD',
                    value: this.setSymbolReplace((this.amt.cartTotalAmt/100).toFixed(2)),
                    ...fbq
                }
            }, this)
        },
        // 初始化
        initialSet() {
            this.country = this.$storage.get('country') || {}
            this.currency = this.$storage.get('currency') || {}
            this.hasLogin = this.$login()
        }
    },
}
